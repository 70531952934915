<template lang="pug">
div
  Section(main='选手须知' bg='Instructions')
  v-row
    v-col
      Subsection 报名细则
      ol.details
        li 本次活动面向全国所有高校的本科生与研究生。
        li 所有参赛者应于报名开始后下载简历模板，并完整填写相关信息。
        li 
          | 参赛选手请
          b 以队伍为单位
          | ，于
          b 5月5日0:00至5月15日24:00
          | 将简历发送至大赛指定邮箱
          a(href='mailto:hackpku@outlook.com') hackpku@outlook.com
          | 。
        li
          | 选手依据简历模板填写简历。简历分为
          b 个人部分与团队部分
          | 。每位选手都需填写简历的个人部分；只有队长需要填写简历的团队部分。
        li 
          | 请如实填写个人信息，确保联系方式有效。若无法及时与您取得联系，可能会影响参赛资格、成绩评定和奖金发放等。
        li 
          | 填写完简历后，请将其保存为（
          code .pdf
          b 格式）， 并和学生证双面照片放进同一个文件夹
          | 。简历和学生证照片均命名为
          b 【姓名】
          | 。文件夹命名为
          b 【大学名-姓名】
          | 。
        li
          | 队长收集本队所有队员的报名材料文件夹（包括自己），打包成一个（
          code .zip
          b 格式文件）
          | ，作为附件发送到大赛指定邮箱。邮件和zip格式附件均命名为
          b 【队名-报名材料】
          | 。
        li
          b 请注意：提交简历即表示您许可将简历中的信息提供给赛事主办方，用于与赛事相关的事项。您的简历可能被发送至部分赞助商的人事部门。除此以外，我们承诺不会以任何方式泄露简历中的信息。若您对个人信息安全问题有任何疑问，请联系大赛负责人[电话：18801365090].
        li 邮件发送成功后，我们将于24小时内发送反馈邮件到您的邮箱，请注意查收。若逾期未收到任何反馈（报名成功或简历不合要求），请咨询选手联络负责人[电话：18005971270]。
  v-row
    v-col
      Subsection 赛道介绍
      ol.details
        li
          b 赛道一：AI 赋能的游戏开发
          ol(type='a')
            li
              b 赛道描述：
              | 基于任意开源游戏引擎设计游戏。要求将机器学习（要求：开源模型或自训练模型，组委会将提供算力和部分 API）融入在游戏中，如 AI 原画绘制、自动游戏场景生成、AI 生成画面或情节等。
            li 
              b 面向对象：
              | 不限。
            li
              b 队伍人数：
              | 3-4 人。
            li
              b 评分标准：
              | 游戏可玩性、AI 技术使用的灵活性、创造力、游戏完成度以及代码质量。
        li
          b 赛道二：奇妙的浏览器
          ol(type='a')
            li 
              b 赛道描述：
              | 浏览器是互联网重要入口，也是前沿技术的探索阵地。这里可以跑系统，也可以跑模型。这里跑的系统是否安全？如何在浏览器中运用 Pytorch 一般的推理能力？欢迎来探索奇妙之旅！我们将使用 webgpu 在浏览器运行模型，用 wasm 运行虚拟机并进行攻防。
            li 
              b 面向对象：
              | 不限，建议大二及以上同学。不用担心没有基础，对于新技术，大家的起跑线是一致的。
            li 
              b 队伍人数：
              | 3-4 人。
            li 
              b 评分标准：
              | 完全度评分和性能评分，实现出色 feature 可加分，提出完整 jstorch 方案可额外加分。
</template>

<script>
import Section from './Section'
import Subsection from './Subsection'

export default {
  components: {
    Section,
    Subsection,
  },
}
</script>

<style scoped>
.details {
  line-height: 2rem;
  padding-left: 2rem;
}
</style>
